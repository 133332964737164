import { startOfDay, set, getHours, getMinutes } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export const groupByDate = (alarms, timezone) => {
  const map = new Map();

  for (const alarm of alarms) {
    const zonedDate = utcToZonedTime(alarm.start, timezone);
    const date = startOfDay(zonedDate).toISOString();
    const alarmExists = map.has(date);

    if (!date) continue;
    if (alarmExists) {
      const currentAlarms = map.get(date);
      const dontAddIfExists = currentAlarms.some(a => a.id === alarm.id);

      if (dontAddIfExists) continue;
      map.get(date).push(alarm);
    } else {
      map.set(date, [alarm]);
    }
  }
  return map;
};

export const sortDates = (dates, order) =>
  dates.sort((a, b) => {
    const aDate = +new Date(a[0]);
    const bDate = +new Date(b[0]);

    if (order === "desc") return bDate - aDate;
    return aDate - bDate;
  });

export const dayIsZeros = day =>
  day.hours() === 0 && day.minutes() === 0 && day.seconds() === 0;

export const sortByDate = (alarms = []) =>
  alarms.sort((a, b) => new Date(b.start) - new Date(a.start));

export const sortAlarms = (alarms = []) =>
  [...alarms].sort((a, b) => {
    const aStart = +new Date(a.start);
    const bStart = +new Date(b.start);

    return bStart - aStart;
  });

export const getCurrentYearMonth = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  return { year, month };
};

export const isSameOrAfterDate = (date1, date2) => +date1 >= +date2;
export const isSameOrBeforeDate = (date1, date2) => +date1 <= +date2;

export const getDateTimeFromHHMM = time => {
  const [hours, minutes] = time?.split(":") ?? [0, 0];
  const date = new Date();
  return set(date, { hours, minutes, seconds: 0 });
};

export const formatDateToHHMM = date =>
  `${getHours(date).toString().padStart(2, "0")}:${getMinutes(date)
    .toString()
    .padStart(2, "0")}`;
